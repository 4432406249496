import { useState } from 'react';
import { APP_COLORS } from '../styles';
import img1 from '../assets/about.jpeg';
import banner from '../assets/droneshot.webp';
import { FaPlay } from 'react-icons/fa';
import Dialogue from './common/Dialogue';
import ReactPlayer from 'react-player';


export default function About() {
    let isMobile = window.innerWidth < 500;
    let [playVideo, setPlayVideo] = useState(false)

    return (
        <div style={{ backgroundColor: '#f1f1f1',  paddingTop: isMobile ? 0 : 50,}}>
            <div id={"about"}
                style={styles.videoContainer(banner, isMobile)}>

                <div style={styles.overlayEffect} />
                <div style={{...styles.videoContent, ... isMobile && {width:'80%' } }}>
                    <div
                        className='video-button'
                        onClick={() => setPlayVideo(true)}
                        style={styles.videoButton}>
                        <FaPlay size={ 30} style={{ marginLeft: 3 }} />
                    </div>
                    <div style={{
                        fontSize: isMobile ? '32px' : '48px',
                        fontWeight: 800,
                    }}
                    >
                     Watch Intro Video
                    </div>
                </div>

                {playVideo &&
                    <Dialogue
                        headerText={'INTRO VIDEO'}
                        content={
                                <ReactPlayer
                                    url= {"https://home-dealers.mo.cloudinary.net/videos/intro.webm"}
                                    width="100%"
                                    height="600px"
                                    controls
                                    playing = {true}
                                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                />
                        }
                        show={playVideo}
                        onClose={() => setPlayVideo(null)}
                    />
                }
            </div>

            <div  style={styles.aboutPanel(isMobile)}>
                <div
                    style={{
                        position: 'absolute',
                        width: 'calc(100% - 20px)',
                        fontSize: isMobile ? '70px' : '100px',
                        fontFamily: 'Monoton',
                        zIndex: 1,
                        top: isMobile ?  10 :  45,
                        display: 'flex',
                        justifyContent: 'center',
                        overflow: 'hidden',
                        color: '#D3d3d3',
                        opacity: 0.4,
                    }}>
                    ABOUT
                </div>
                <div style={styles.title}> About </div>
                <div style={styles.subtitle(isMobile)}>
                    We are the biggest wholesaling company residing in Florida, US. We deal in all kinds of properties in residential and commercial.
                </div>
                <div style={styles.content(isMobile)}>
                    <div style={{ width: !isMobile && '50%' }}>
                        <div style={{ fontWeight: 'bolder', fontSize: '24px', marginBottom: '10px', color: APP_COLORS.HEADING_TEXT, }}>
                            Take your business to the next level
                        </div>
                        <p style={{ fontSize: isMobile ? '13px' : '16px', textAlign : isMobile && 'center',  lineHeight: isMobile ? 1.6 : 2, color: APP_COLORS.PRIMARY_TEXT }}>
                            We have an unparalled collection of properties ranging from Single Family Homes, Manufactured Homes, vacant lots, town houses and commercial properties. All our properties are cleared and authorized, and our agents will provide you with the best consultancy. <br />
                            We offer authorized deals for B2B brokerages. All our prospects are authorized and cleared, and since we purchase in bulk, all the overlapping properties are in market for other brokerages.
                        </p>
                    </div>
                    <div style={{
                        width: !isMobile && '50%',
                        height: isMobile && 200,
                        background: `url(${img1})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: ' no-repeat',
                        backgroundSize: 'cover',
                    }} />

                </div>
            </div>


            
        </div>

    )
}

const styles = {
    aboutPanel: (isMobile) => ({
        position: 'relative',
        width: '100%',
        boxSizing: "border-box",
        padding: 20,
        backgroundColor: '#f1f1f1',
        paddingTop: isMobile ? 30 : 100,
    }),
    content:  (isMobile) => ({
        margin: '10% 10%',
        gap: 20,
        display: 'flex',
        flexDirection: isMobile && 'column',
        justifyContent: 'space-between',
    }),
    title: {
        textAlign: 'center',
        fontSize: "42px",
        fontWeight: 'bolder',
        color: APP_COLORS.BUTTON_BACKGROUND,
        margin: '20px 0px',
        zIndex: 9,
        position: 'relative',
    },
    subtitle:  (isMobile)=> ({
        margin: '0 auto',
        width: isMobile ? '80vw' : '50vw',
        textAlign: 'center',
        fontSize: isMobile ? "16px" : "18px",
        fontWeight:'bold',
        color: APP_COLORS.PRIMARY_TEXT,
    }),

    videoContainer: (banner, isMobile) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        boxSizing: "border-box",
        height: '60vh',
        position: 'relative',
        background: `url(${banner})`,
        backgroundRepeat: ' no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        marginTop: isMobile ? 10 :  200,
    }),
    overlayEffect: {
        position: 'absolute',
        background: APP_COLORS.BUTTON_BACKGROUND,
        width: "100%",
        height: "100%",
        opacity: 0.6,
    },
    videoContent: {
        zIndex: 1,
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '50%',
        textAlign: 'center',
        gap: 50
    },
    videoButton: {
        background: 'white',
        borderRadius: '50%',
        height: '80px',
        width: '80px',
        border: 'none',
        color: APP_COLORS.BUTTON_BACKGROUND,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    }
}