// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination, Navigation, EffectFade, Autoplay } from "swiper";
// Import Swiper styles
import 'swiper/css';

import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

export default ({ images, onOpenImageGallery, autoplay, navigation, pagination }) => {
  return (
    <Swiper
      autoplay={autoplay && {
        delay: 7000,
      }}
      pagination={  {
        type: pagination && "fraction",
      }}
      navigation={navigation}

      effect={"fade"}
      modules={[EffectFade, Pagination, Navigation, Autoplay]}
      className="mySwiper"

    >
      {images && images.length && images.map((image, idx) => (
        <SwiperSlide ><img style={{borderRadius:'5px', }} src={image} onClick={() => onOpenImageGallery && onOpenImageGallery(idx, images)} /></SwiperSlide>
      ))}
    </Swiper>
  );
};