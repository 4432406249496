import { APP_COLORS } from "../styles"

export default function ServiceCard ({
    title, 
    text, 
    icon, 
}) {
    let isMobile = window.innerWidth < 500;
   return (
   <div style={styles.card(isMobile)} className='service-card' >
      <div style={{marginTop:50 }} > {icon} </div>
    <div style={{fontSize:'24px', fontWeight:'bold', marginBottom:10, textAlign:'center'}}>{title}</div>
    <div style={{textAlign:'center', fontSize:'16px'}}>{text}</div>
   </div>) 
}

const styles = {
    card: (isMobile) => ({
        height: isMobile ? '160px' : '200px',
        padding:20,
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        gap:2,
        borderRadius:5,
        width: isMobile ? '200px' : '300px'
    }),

}