import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import {  APP_COLORS } from "../styles";
import TextField from '../components/common/TextField';
import Loading from '../components/common/Loading';
import Button from '../components/common/Button';
import logo from '../assets/logo.png';
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '../components/common/Dialog';
import 'react-toastify/dist/ReactToastify.css';

import { signUp, selectIsLogginIn, selectLoginError, selectIsLoggedIn, selectsignUpStatus } from '../store/user';

function SignUpDialog({onClose}) {
    const width = window.innerWidth;
    const isMobile = width < 700;
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let isLoggingIn = useSelector(selectIsLogginIn);
    let loginError = useSelector(selectLoginError);
    let isLoggedIn = useSelector(selectIsLoggedIn);

    let signUpStatus = useSelector(selectsignUpStatus)

  let [formData, setFormData] = useState({
    email: '',
    password: '',
    errors: {}
  })


  useEffect(() => {
    if (signUpStatus == 'SUCCESS') {
      toast.success(signUpStatus)
      onClose()
    }else{
      toast.error(signUpStatus)
    }
  }, [  signUpStatus])



  const handleChange = (target) => {
    const { name, value } = target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    })
    )
  }

  const handleSubmit = () => {
    dispatch(signUp(formData))
    // dispatch(login(formData))
  }

  return (
    <Dialog showClose onClose={onClose} >
    <div style={styles.container(isMobile) }>
      <form style={{width:'100%', height:'100%'}}>
       <div className={'logo-rotate'} style={{display:'flex', justifyContent:'center'}}>
        <div style={{maxWidth:'200px',}}>
          <Link to='/'>
          <img src={logo} style={{width:'100%', width:'100%'}} />
          </Link>  
        </div>
        
       </div>

        <div style={styles.formHeader}>
          Dont have an account? Join Us.
        </div>
        <section style={styles.formBody(isMobile)}>
          <TextField
            name={'email'}
            text={formData.email}
            placeholder={'email'}
            onEnter={handleSubmit}
            // onCancel = {} 
            containerStyle = {{marginBottom:5, borderRadius:5 }} 
            onTextChange={handleChange}
            autoFocus={true}
          // onFocus = {}
          // onClear = {} 
          />
          <TextField
            name={'password'}
            text={formData.password}
            type={'password'}
            placeholder={'password'}
            onEnter={handleSubmit}
            // onCancel = {} 
            containerStyle = {{marginBottom:5, borderRadius:5 }} 
            onTextChange={handleChange}
            autoFocus={true}
          // onFocus = {}
          // onClear = {} 
          />

          <section style={{paddingTop:10}}>
          {isLoggingIn ?
            <Loading />
            :
            <div style={{
              width:'100%', display:'flex', justifyContent:'center'}}>
            <Button
              label={'sign up'}
              onClick={handleSubmit}
              style={{fontSize:'16px', width:'50%', }}
            />
            </div>
          }
          </section>

        </section>
       
        <section style={styles.formFooter}>
          Home Dealers © 2023 
        </section>
      </form>

      <ToastContainer
        position="top-right"
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
     
    </div>
    </Dialog>
  )
}
export default SignUpDialog;


const styles = {
  container: (isMobile) =>({
    boxSizing: 'border-box',
    color: APP_COLORS.PRIMARY_TEXT,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: isMobile ? '320px' : '500px',
    // background: '-webkit-linear-gradient(to right, #4CA1AF, #2C3E50)',  /* Chrome 10-25, Safari 5.1-6 */
    // background: 'linear-gradient(to right, #4CA1AF, #2C3E50)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: isMobile ? "10px" : "30px",
    borderRadius: 8,
    background: APP_COLORS.PAGE_BACKGROUND,
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
  }),
  formContainer: (isMobile) => ({
   
  }),
  formHeader: {
    fontSize: '20px',
    padding: '10px',
  },
  formBody: (isMobile) =>  ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: isMobile ? '10px 20px' : '20px 40px',
  }),
  formFooter: {
    marginTop: '10px',
    padding: 20,
    paddingBottom:0,
    borderTop: `1px solid ${APP_COLORS.SECONDARY_TEXT}`,

  }
}
