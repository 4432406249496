import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BsInstagram, BsFacebook, BsLinkedin } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import { MdOutlinePhoneInTalk } from 'react-icons/md';
import { APP_COLORS } from '../styles';
import logo from '../assets/logo.png';
import { LOGIN_PAGE_ROUTE, SIGNUP_PAGE_ROUTE } from '../routes';
import { selectIsLoggedIn } from '../store/user';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import Button from './common/Button';


export default function Header() {
    let isLoggedIn = useSelector(selectIsLoggedIn)
    let [showlogo, setShowlogo] = useState(false)
    let isTablet = window.innerWidth < 800;
    let isMobile = window.innerWidth < 500;

    let [dropdown, showDropDown] = useState(false)
    let [signUp, showSignUp] = useState(false)

    useEffect(() => {
        document.addEventListener('scroll', isSticky)
        return () => document.removeEventListener('scroll', isSticky)
    })

    const isSticky = () => {
        const header = document.querySelector('.header')
        const scrollTop = window.scrollY;
        if (scrollTop >= 120) {
            header.classList.add("is-sticky")
            setShowlogo(true);
        } else {
            header.classList.remove("is-sticky");
            setShowlogo(false);
        }
    }

    const scroll = (e, id) => {
        const section = document.querySelector(id);
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    return (
        <div style={styles.header(isTablet, isMobile)}>
            {
                isTablet ?
                    <div>
                        <div style={styles.subHeader} className='header'>
                            <div style={styles.mobile_logo} />
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 4, alignItems: 'center' }}>
                                {!isMobile &&
                                    <>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 4, alignItems: 'center' }}>
                                            {/* <div style={{ cursor: 'pointer', display: 'flex', gap: 2, alignItems: 'center' }} >
                                                <HiOutlineMail size={'16'} />
                                                <div style={{ fontSize: '14px' }}>
                                                <a href="mailto:hamdankhanzada@home-dealers.com"> hamdankhanzada@home-dealers.com </a>
                                                </div>
                                            </div> */}
                                            <div style={{ cursor: 'pointer', display: 'flex', gap: 2, alignItems: 'center' }}>
                                                <MdOutlinePhoneInTalk size={'16'} />
                                                <div style={{ fontSize: '14px' }}>
                                                <a href="tel:1-813-344-0435"> +1 813 344 0435</a>
                                                </div>
                                            </div>

                                            <div className='social-icons' style={{ paddingTop: 1 }} >
                                                <a href='https://www.facebook.com/Home-Dealers-LLC-103730065622512' target="_blank" rel="noopener noreferrer">
                                                    <BsFacebook className='social-icons' size={'14'} />
                                                </a>
                                            </div>
                                        </div>

                                    </>
                                }
                                {dropdown ?
                                    <AiOutlineClose style={{ fontSize: '18px' }} onClick={() => showDropDown(!dropdown)} />
                                    :
                                    <AiOutlineMenu style={{ fontSize: '18px' }} onClick={() => showDropDown(!dropdown)} />
                                }

                            </div>
                            {dropdown &&
                                <div style={{
                                    position: 'absolute',
                                    top: 40,
                                    background: 'rgba(255,255,255,0.6)',
                                    width: '100%',
                                    zIndex: 9,
                                    left: 0,
                                }}>
                                    <div style={{ boxSizing: 'border-box', display: 'flex', flexDirection: 'column', justifyContent: 'center', listStyle: 'none', }}>
                                        <li
                                            onClick={(e) => scroll(e, '#home')}
                                            className='list-item'>
                                            Home
                                        </li>
                                        <li
                                            onClick={(e) => scroll(e, '#about')}
                                            className='list-item'>
                                            About Us
                                        </li>
                                        <li
                                            onClick={(e) => scroll(e, '#services')}
                                            className='list-item'>
                                            What We Do
                                        </li>
                                        <li
                                            onClick={(e) => scroll(e, '#contact_us')}
                                            className='list-item'>
                                            Contact
                                        </li>
                                        
                                        <Link to={isLoggedIn ? '/dashboard' : SIGNUP_PAGE_ROUTE}>
                                        <li className='list-item' >
                                                {isLoggedIn ? 'PROFILE' : 'SIGN IN'}
                                        </li>
                                        </Link>

                                    </div>
                                </div>}
                        </div>
                        
                    </div>
                    :
                    <>
                        {!showlogo && <div style={styles.logo} />}
                        <div style={styles.subHeader}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 5, }}>
                                {/* <div style={{ cursor: 'pointer', display: 'flex', gap: 2, alignItems: 'center' }} >
                                    <HiOutlineMail size={'16'} />
                                    <div style={{ fontSize: '14px' }}>
                                    <a href="mailto:hamdankhanzada@home-dealers.com"> hamdankhanzada@home-dealers.com</a>
                                    </div>
                                </div> */}
                                <div style={{ cursor: 'pointer', display: 'flex', gap: 2, alignItems: 'center' }}>
                                    <MdOutlinePhoneInTalk size={'16'} />
                                    <div style={{ fontSize: '14px' }}>
                                    <a href="tel:1-813-344-0435"> +1 813 344 0435 </a>
                                    </div>
                                </div>

                            </div>
                            
                            <div style={styles.social} >
                            <div className='social-icons' >
                                <a href='https://www.facebook.com/Home-Dealers-LLC-103730065622512' target="_blank" rel="noopener noreferrer">
                                    <BsFacebook className='social-icons' size={20} />
                                </a>
                            </div>
                            </div>
                        </div >
                        <nav style={styles.mainHeader} className='header fill' >
                            {showlogo && <div style={styles.sm_logo} />}
                            <ul style={styles.listStyle}>
                                <li
                                    onClick={(e) => scroll(e, '#home')}>
                                    <a>Home</a> 
                                </li>
                                <li
                                    onClick={(e) => scroll(e, '#about')}>
                                    <a>About</a>
                                </li>
                                <li
                                    onClick={(e) => scroll(e, '#services')}>
                                    <a>What we do</a>
                                </li>
                                <li
                                    onClick={(e) => scroll(e, '#contact_us')}>
                                    <a>Contact</a>
                                </li>
                                
                                <Link to={isLoggedIn ? '/dashboard' : SIGNUP_PAGE_ROUTE}>
                                    <div 
                                        style={{marginTop:10}}
                                        className='button-hover'> {isLoggedIn ? 'Dashboard' : 'Log in'} </div>
                                </Link>

                            </ul>

                        </nav>
                    </>
            }
          
        </div>
    )
}

const styles = {
    header: (isTablet, isMobile ) => ({
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: isTablet ? '40px' : '100px',
        
    }),
    logo: {
        position: 'absolute',
        backgroundImage: `url(${logo})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '100px',
        width: '400px',
        zIndex: 9,
    },
    sm_logo: {
        position: 'absolute',
        backgroundImage: `url(${logo})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '200px',
        width: '200px',
        top: 0,
        left: 5,
        zIndex: 9,
    },
    mobile_logo: {
        position: 'absolute',
        backgroundImage: `url(${logo})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '40px',
        width: '120px',
        top: 5,
        left: 5,
        zIndex: 9,
    },

    subHeader: {
        boxSizing: 'border-box',
        height: '40px',
        padding: '10px 20px',
        background: APP_COLORS.META_BACKGROUND,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    mainHeader: {
        background: '#fcfcfc',
        padding: '10px 10px',
        boxSizing: 'border-box',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: "flex-end",
        gap: 20,
        fontFamily: 'Titillium Web',
    },
    listStyle: {
        listStyle: 'none',
        display: 'flex',
        justifyContent: "center",
        alignItems:'center',
        gap:20,
    },
    social: {
        display: 'flex',
        flexDirection: 'row',
        alignItems:'center',
        paddingLeft: 10,
        cursor: 'pointer',
        gap:10
    }

}