
import { APP_COLORS } from '../styles';
import { RiShieldFlashFill, RiHomeGearFill, RiFolderUserFill, RiGroup2Fill } from 'react-icons/ri';
import {GiHandTruck, GiHouse} from 'react-icons/gi';
import {SiHomebridge} from 'react-icons/si';
import Card from './ServiceCard';

export default function Services() {
    
    let isMobile = window.innerWidth < 500;

    return (
        
        <div id={"services"} style={styles.servicesPanel(isMobile)}>
            <div
                style={{
                    position: 'absolute',
                    width: 'calc(100% - 20px)',
                    zIndex: -1,
                    textAlign: isMobile && 'center',
                    fontSize: isMobile ? '50px' : '100px',
                    fontFamily: 'Monoton',
                    top: isMobile ? 50 : 80,
                    display: 'flex',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    color: '#D3d3d3',
                    opacity: 0.4,
                }}>
                WHAT WE DO
            </div>
            <div style={styles.title}> What We Do </div>
            <div style={styles.subtitle}>
                We excel in all domains related to real estate.
            </div>

            <div style={styles.content(isMobile)}>
                <Card
                    icon={<GiHandTruck size='52px' />}
                    title='Wholesaling'
                    // text='Lore Ipsum Lorem Lore Ipsum Lorem Lore Ipsum Lorem Lore Ipsum Lorem'
                />
                <Card
                    icon={<RiHomeGearFill size='52px' />}
                    title='Fix and Flip'
                    // text='Lore Ipsum Lorem Lore Ipsum Lorem Lore Ipsum Lorem Lore Ipsum Lorem'
                />
                <Card
                    icon={<RiFolderUserFill size='52px' />}
                    title='Investment Portfolios'
                    // text='Lore Ipsum Lorem Lore Ipsum Lorem Lore Ipsum Lorem Lore Ipsum Lorem'
                />
            </div>
            <div style={styles.content(isMobile)}>
                <Card
                    icon={<GiHouse size='52px' />}
                    title='Manufactured Homes'
                    // text='Lore Ipsum Lorem Lore Ipsum Lorem Lore Ipsum Lorem Lore Ipsum Lorem'
                />
                <Card
                    icon={<SiHomebridge size='52px' />}
                    title='Rental Properties (AirBNB)'
                    // text='Lore Ipsum Lorem Lore Ipsum Lorem Lore Ipsum Lorem Lore Ipsum Lorem'
                />
                <Card
                    icon={<RiGroup2Fill size='52px' />}
                    title='Real Estate Consultancy'
                    // text='Lore Ipsum Lorem Lore Ipsum Lorem Lore Ipsum Lorem Lore Ipsum Lorem'
                />
            </div>

        </div>
    )
}

const styles = {
    servicesPanel: (isMobile) => ({
        position: 'relative',
        width: '100%',
        boxSizing: "border-box",
        padding: 20,
        paddingTop: isMobile ? 50 : 150,
        paddingBottom: 100,
    }),
    content: (isMobile) => ({
        display: 'flex',
        margin: '30px 10%',
        gap: 20,
        flexDirection: isMobile && 'column',
        justifyContent:'center',
        alignItems:'center',
    }),
    title: {
        textAlign: 'center',
        fontSize: "42px",
        fontWeight: 'bolder',
        color: APP_COLORS.BUTTON_BACKGROUND,
        margin: '20px 0px'
    },
    subtitle: {
        margin: '0 auto',
        width: '50vw',
        textAlign: 'center',
        color: APP_COLORS.BUTTON_BACKGROUND,
    }
}