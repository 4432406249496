export const APP_COLORS = {
    PAGE_BACKGROUND: '#fcfcfc',
    SECONDARY_BACKGROUND:'#585858',
    META_BACKGROUND:'#ededed',
  
    BUTTON_BACKGROUND: '#005f9c',
    THEME_GRAY:'#585858',
  
    HEADING_TEXT:"#005f9c",
    PRIMARY_TEXT:"#606060",
    
    SECONDARY_TEXT:"#F1F1F1",
  
  }
