import { APP_COLORS } from "../styles"

export default function ValueCard ({
    title, 
    text, 
    icon, 
    style,
    isMobile,
}) {


   return (
   <div style={{...styles.card, ...style}} className='value-card'  >
      <div style={{marginTop:20}}> {icon} </div>
    <div style={{fontSize:'24px', fontWeight:'bold', marginBottom:10, textAlign:'center', color: APP_COLORS.HEADING_TEXT}}>{title}</div>
    <div style={{textAlign:'center', fontSize:'16px', overflow:'hidden', textOverflow:'ellipsis', }}>{text}</div>
   </div>) 
}

const styles = {
    card:{
        height:'200px',
        maxWidth:'300px',
        minWidth:'200px',
        background:APP_COLORS.PAGE_BACKGROUND,
        color:APP_COLORS.HEADING_TEXT,
        padding:20,
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        gap:2,
        borderRadius:10,
        // border:`2px solid ${APP_COLORS.BUTTON_BACKGROUND}`
    },

}