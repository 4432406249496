import { useState, useRef } from "react";
import { FaMailBulk, FaPhone } from "react-icons/fa";
import emailjs from '@emailjs/browser';

import TextField from "./common/TextField";
import Select from './common/Select';
import Button from "./common/Button";
import { APP_COLORS } from "../styles"
import { validate, checkForErrors } from '../utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Privacy from "./Privacy";


export default function ContactUs() {

    let [formData, setFormData] = useState({
        name: '',
        email: '',
        number: '',
        type: '',
        message: ''
    })
    let [consentGiven, setConsentGiven] = useState(false)
    let formRef = useRef();

    let [errors, setErrors] = useState({})
    let errorRef = useRef(errors);
    let [isSubmitting, setIsSubmitting] = useState(false)

    let [showPrivacy, setShowPrivacy] = useState(false)
    let isMobile = window.innerWidth < 500;

    const handleChange = (target) => {
        const { name, value } = target;

        let returnedErrors = validate(name, value)

        setErrors(errors => ({
            ...errors,
            [name]: returnedErrors[name]
        }));

        setFormData(prev => ({
            ...prev,
            [name]: value
        })
        )
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        for (const key in formData) {
            let returnedErrors = validate(key, formData[key])
            errorRef.current[key] = returnedErrors[key]
            setErrors(errors => ({
                ...errors,
                [key]: returnedErrors[key]
            }));
        }
        setIsSubmitting(true)
        let hasErrors = checkForErrors(errorRef.current)

        // check if consent is given here 
        if (!hasErrors && consentGiven) {
            console.log(formData)
            emailjs.sendForm(`service_ocwds3g`, process.env.REACT_APP_EMAILJS_TEMPLATEID, formRef.current, process.env.REACT_APP_EMAILJS_USERID)
                .then((result) => {
                    console.log(result.text)
                    toast.info("Your form has been submitted successfully! Our team will get back to you in next 24 hours.")
                    setIsSubmitting(false)
                    setFormData({
                        name: '',
                        email: '',
                        number: '',
                        type: '',
                        message: ''
                    })
                },
                    (error) => {
                        alert("An error occurred, Please try again", error.text);
                        toast.warning('Your form couldnt submit, Please Try again!')
                        setIsSubmitting(false)
                    })
        } else {
            toast.error('Fill Required Fields!')
            setIsSubmitting(false)
        }
    }

    return (
        <div id={'contact_us'} style={styles.container}>
            <div
                style={{
                    position: 'absolute',
                    width: 'calc(100% - 20px)',
                    zIndex: -1,
                    fontSize: isMobile ? '40px' : '100px',
                    fontFamily: 'Monoton',
                    top: isMobile ? 110 : 40,
                    display: 'flex',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    color: '#D3d3d3',
                    opacity: 0.4,
                }}>
                CONTACT US
            </div>
            <div style={styles.title}> Contact Us </div>
            <div style={styles.subtitle(isMobile)}> Make an inquiry. Fill out this form below. </div>
            <form
                ref={formRef}
                onSubmit={handleSubmit}
                style={{
                    padding: isMobile ? '20px 20px' : '40px 100px',
                    display: 'flex',
                    flexDirection: isMobile && 'column-reverse',
                    justifyContent: 'space-between',
                    gap: 20,
                }}>

                <div style={{ width: !isMobile && '30%', display: 'flex', flexDirection: "column", paddingTop: !isMobile && 40, }}>
                    <div style={{ padding: '10px 50px', display: 'flex', alignItems: 'center', padding: 20, gap: 20, }}>
                        <div style={{ height: '20px', minWidth: '20px', padding: 20, border: '1px solid #888', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                            <FaMailBulk color={APP_COLORS.BUTTON_BACKGROUND} size={20} />
                        </div>
                        <div >
                            <b> EMAIL</b>
                            <div style={{ fontSize: '14px' }}>
                                sales@home-dealers.com
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: '10px 50px', display: 'flex', alignItems: 'center', padding: 20, gap: 20 }}>
                        <div style={{ height: '20px', minWidth: '20px', padding: 20, border: '1px solid #888', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                            <FaPhone color={APP_COLORS.BUTTON_BACKGROUND} size={20} />
                        </div>
                        <div>
                            <b>PHONE </b>
                            <div style={{ fontSize: '14px' }}>
                                +1 813 344 0435
                            </div>
                        </div>
                    </div>
                </div>


                <div style={styles.form}>
                    <div style={styles.panel(isMobile)}>
                        <div style={{ width: '100%' }}>
                            <TextField
                                name={'name'}
                                text={formData.name}
                                inputStyle={{ padding: 15, borderRadius: 10, background: APP_COLORS.META_BACKGROUND }}
                                placeholder={'Name'}
                                onTextChange={handleChange}
                            />
                            <span style={styles.errors}>{errors.name}</span>
                        </div>

                        <div style={{ width: '100%' }}>
                            <TextField
                                name={'email'}
                                text={formData.email}
                                inputStyle={{ padding: 15, borderRadius: 10, background: APP_COLORS.META_BACKGROUND }}
                                placeholder={'Email'}
                                onTextChange={handleChange}
                            />
                            <span style={styles.errors}>{errors.email}</span>
                        </div>

                    </div>

                    <div style={styles.panel(isMobile)}>
                        <div style={{ width: '100%' }}>
                            <TextField
                                name={'number'}
                                text={formData.number}
                                inputStyle={{ padding: 15, borderRadius: 10, background: APP_COLORS.META_BACKGROUND }}
                                placeholder={'Number'}
                                onTextChange={handleChange}
                            />
                            <span style={styles.errors}>{errors.number}</span>
                        </div>
                        <div style={{ width: '100%' }}>
                            <Select
                                onChange={(e) => handleChange(e.target)}
                                name={'type'}
                                value={formData.type}
                                style={{ background: APP_COLORS.META_BACKGROUND, color: APP_COLORS.PRIMARY_TEXT, height: '35px', width: '94%', }}
                                options=
                                {[
                                    { id: 'realtor', text: 'Realtor ', value: 'realtor', },
                                    { id: 'wholeseller', text: 'Whole Seller', value: 'wholeseller', },
                                    { id: 'homeowner', text: 'Home Owner', value: 'homeowner', },
                                    { id: 'investor', text: 'Investor', value: 'investor', },
                                    { id: 'broker', text: 'Broker', value: 'broker', },
                                    { id: 'acquisition_manager', text: 'Acquisition Manager', value: 'acquisition_manager', },
                                ]}
                            />
                            <span style={styles.errors}>{errors.type}</span>
                        </div>

                    </div>
                    <div style={styles.panel(isMobile)}>
                        <TextField
                            name={'message'}
                            text={formData.message}
                            inputStyle={{ fontFamily: 'default', padding: 15, borderRadius: 10, background: APP_COLORS.META_BACKGROUND }}
                            placeholder={'Message'}
                            multiLine={true}
                            onTextChange={handleChange}
                        />
                    </div>


                    <div style={{ ...styles.panel(isMobile), }}>
                        <div style={{ display: 'flex' }}>
                            <input
                                type="checkbox"
                                defaultChecked={consentGiven}
                                onChange={({ target }) => {
                                    const value = target.type === 'checkbox' ? target.checked : target.value;
                                    setConsentGiven(value)
                                    console.log(value)
                                }}
                            />
                            <div>
                                By submitting this form you are authorizing homdealers to send sms/text. 
                                <span 
                                onClick={()=>{
                                    setShowPrivacy(true)
                                }}
                                style={{
                                   color:'blue' ,
                                   cursor:'pointer'
                                }}> privacy policy</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', padding: 20, }}>
                        {isSubmitting ?
                            <div> Submitting... </div> :
                            <input
                                style={{
                                    fontSize: '18px',
                                    padding: '10px 20px',
                                    background: APP_COLORS.BUTTON_BACKGROUND,
                                    color: APP_COLORS.SECONDARY_TEXT,
                                    outline: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    borderRadius: 5,
                                }}
                                type={'submit'}
                            />}
                    </div>
                    <Privacy show={showPrivacy} onClose={()=>setShowPrivacy(null)} />
                </div>
            </form>
            <ToastContainer
                position="top-center"
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </div>
    )
}

const styles = {
    container: {
        position: 'relative',
        width: '100%',
        boxSizing: "border-box",
        padding: 20,
        paddingTop: 100,

    },
    title: {
        textAlign: 'center',
        fontSize: "42px",
        fontWeight: 'bolder',
        color: APP_COLORS.BUTTON_BACKGROUND,
        margin: '20px 0px'
    },
    subtitle: (isMobile) => ({
        margin: '0 auto',
        width: isMobile ? '80vw' : '50vw',
        textAlign: 'center',
        fontSize: isMobile ? "16px" : "18px",
        fontWeight: 'bold',
        color: APP_COLORS.PRIMARY_TEXT,
    }),
    form: {
        flex: 1,
    },
    panel: (isMobile) => ({
        display: 'flex',
        flexDirection: isMobile && 'column',
        gap: isMobile ? 0 : 50,
        padding: !isMobile && 10,
    }),
    errors: {
        padding: 5,
        fontSize: '12px',
        color: 'red',
    },
}