import { APP_COLORS } from "../styles";
import CountUp from 'react-countup';
import { FaRegHandshake, FaHandHoldingUsd, FaHome, FaHandshake } from "react-icons/fa";
import VisibilitySensor from 'react-visibility-sensor';


export default function Info() {
    let isMobile = window.innerWidth < 500;
    return (
        <div style={styles.infoStyle(isMobile)}>
            <div style={{ height:"100px", width:'200px', padding:20, display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
            <FaHandHoldingUsd fontSize={40} style={{color:APP_COLORS.BUTTON_BACKGROUND, paddingBottom:5}} />
            <VisibilitySensor partialVisibility scrollCheck={true} offset={{ top: 100 }} onChange={(visible)=>{ console.log(visible)}} >
            {({ isVisible }) => (
             <div >
                {isVisible ? 
                <CountUp 
                end = {2000}
                suffix = {'+'}
                duration = {3}
                useEasing= {true}
                style={{fontSize:'28px', color:APP_COLORS.PRIMARY_TEXT, fontWeight:'bold',}}
                />   
                 : 
                '1'}
                </div>
                )}
                </VisibilitySensor>
                <div>
                Confirmed closings
                </div>
            </div>
            <div style={{ height:"100px", width:'200px', padding:20, display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
            <FaHandshake fontSize={40} style={{color:APP_COLORS.BUTTON_BACKGROUND}} />
            <VisibilitySensor partialVisibility scrollCheck={true} offset={{ top: 100 }} onChange={(visible)=>{ console.log(visible)}} >
            {({ isVisible }) => (
             <div >
                {isVisible ? 
                <CountUp 
                end = {400}
                suffix = {'+'}
                duration = {3}
                useEasing= {true}
                style={{fontSize:'28px', color:APP_COLORS.PRIMARY_TEXT, fontWeight:'bold',}}
                />   
                 : 
                '1'}
                </div>
                )}
                </VisibilitySensor>
                <div>
                Satisfied clients
                </div>
            </div>

            <div style={{ height:"100px", width:'200px', padding:20, display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
            <FaHome fontSize={40} style={{color:APP_COLORS.BUTTON_BACKGROUND, paddingBottom:5}} />
            <VisibilitySensor partialVisibility scrollCheck={true} offset={{ top: 100 }} onChange={(visible)=>{ console.log(visible)}} >
            {({ isVisible }) => (
             <div >
                {isVisible ? 
                <CountUp 
                end = {5000}
                suffix = {'+'}
                duration = {3}
                useEasing= {true}
                style={{fontSize:'28px', color:APP_COLORS.PRIMARY_TEXT, fontWeight:'bold',}}
                />   
                 : 
                '1'}
                </div>
                )}
                </VisibilitySensor>
                <div>
                Properties
                </div>
            </div>
           
        </div>
    )
}

const styles = {
    infoStyle: (isMobile)=> ({
        display: 'flex',
        padding: '50px 20%',
        flexDirection: isMobile && 'column',
        justifyContent:'space-around',
        alignItems:'center',
        background: APP_COLORS.META_BACKGROUND,
        
    })

}