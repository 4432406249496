import { useEffect } from 'react';
import Header from '../components/Header';
import Values from '../components/Values';
import Services from '../components/Services';
import About from '../components/About';
import Testimonials from '../components/Testimonials';
import Info from '../components/Info';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import Carousel from '../components/common/Carousel';

import { APP_COLORS } from '../styles';
import { Link } from 'react-router-dom';
import { SIGNUP_PAGE_ROUTE } from '../routes';

export default function HomePage() {
    
    let bg1 = "https://home-dealers.mo.cloudinary.net/bgs/bg1.jpg"
    let bg2 = "https://home-dealers.mo.cloudinary.net/bgs/bg2.jpg"
    let bg3 = "https://home-dealers.mo.cloudinary.net/bgs/bg3.jpg"
    let bg4 = "https://home-dealers.mo.cloudinary.net/bgs/bg4.jpg"
    let bg5 = "https://home-dealers.mo.cloudinary.net/bgs/bg5.jpg"
    let bg6 = "https://home-dealers.mo.cloudinary.net/bgs/bg6.jpg"
    let bg7 = "https://home-dealers.mo.cloudinary.net/bgs/bg7.jpg"
    let bg8 = "https://home-dealers.mo.cloudinary.net/bgs/bg8.jpg"
    let bg9 = "https://home-dealers.mo.cloudinary.net/bgs/bg9.jpg"
    let bg10 = "https://home-dealers.mo.cloudinary.net/bgs/bg10.webp"

    let isMobile = window.innerWidth < 700

    return (
        <div id={'home'} >
            <Header />
            <div
                style={{
                    height: isMobile ? "70vh" : '90vh',
                    position: 'relative'
                }}>
                <div style={{
                    zIndex: 2,
                    position: 'absolute',
                    padding: isMobile ? '10px 10px' : '20px 50px',
                    height: !isMobile && '100px',
                    width: '100%',
                    boxSizing: 'border-box',
                    top: isMobile ? '55vh' : '65vh',
                    background: 'rgba(0,0,0, 0.40)',
                    display: 'flex',
                    justifyContent: isMobile ? 'center' : 'space-between',
                    alignItems: 'center',
                    gap: 20,
                }}>
                    <div style={{
                        color: APP_COLORS.SECONDARY_TEXT,
                        fontWeight: 'bolder',
                        textAlign: isMobile && 'center',
                        fontSize: isMobile ? '20px' : '46px'
                    }}>
                        The Perfect Home In Your Reach.
                    </div>

                    <Link
                        to={SIGNUP_PAGE_ROUTE}
                    >
                        <div
                            onClick={() => {
                                const section = document.querySelector('#contact_us');
                                section.scrollIntoView({ behavior: 'smooth', block: 'start' });
                            }}
                            style={{ width: isMobile ? '100px' : '200px', padding: '10px 10px', fontSize: isMobile ? 13 : 22 }}
                            className='button-hover'> Get Started </div>
                    </Link>
                    {/* <Button
                            label={'Get Started'}
                            style={{ width: isMobile ? '100px' : '200px', padding: '10px 10px', fontSize: isMobile && 13 }}
                            onClick={() => {
                                const section = document.querySelector('#contact_us');
                                section.scrollIntoView({ behavior: 'smooth', block: 'start' });
                            }}
                        /> */}
                </div>
                <Carousel
                    images={[bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9, bg10]}
                    autoplay
                    navigation={false}
                    pagination={false}
                />
            </div>
            <Values />

            <About />
            <Services />
            <Testimonials />
            <Info />
            {<ContactUs />}

            <Footer />
        </div>
    )
}

const styles = {

}