import banner from '../assets/testimonials.jpg';

import { FaPlay } from 'react-icons/fa';
import { APP_COLORS } from '../styles';
import { useState } from 'react';

import Dialogue from './common/Dialogue';
import ReactPlayer from 'react-player';


export default function Testimonials() {

    let [playVideo, setPlayVideo ] = useState ( false )
    return (
        <div
            style={styles.videoContainer(banner)}>

            <div style={styles.overlayEffect} />
            <div style={styles.videoContent}>
                <div style={{
                    fontSize: '48px',
                    fontWeight: 800,
                }}>
                    Testimonials
                </div>
                <div
                    className='video-button'
                    onClick={() => setPlayVideo(true)}
                    style={styles.videoButton}>
                    <FaPlay size={30} style={{ marginLeft: 3 }} />
                </div>

            </div>
            {playVideo &&
                    <Dialogue
                        headerText={'TESTIMONIALS'}
                        content={
                                <ReactPlayer
                                    url= {"https://home-dealers.mo.cloudinary.net/videos/test.webm"}
                                    width="100%"
                                    height="600px"
                                    controls
                                    playing = {true}
                                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                />
                        }
                        show={playVideo}
                        onClose={() => setPlayVideo(null)}
                    />
                }
        </div>
    )
}

const styles = {
    videoContainer: (banner) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        boxSizing: "border-box",
        height: '50vh',
        position: 'relative',
        background: `url(${banner})`,
        backgroundRepeat: ' no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }),
    overlayEffect: {
        position: 'absolute',
        background: APP_COLORS.BUTTON_BACKGROUND,
        width: "100%",
        height: "100%",
        opacity: 0.6
    },
    videoContent: {
        zIndex: 1,
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '50%',
        textAlign: 'center',
        gap: 50
    },
    videoButton: {
        background: 'white',
        borderRadius: '50%',
        height: '80px',
        width: '80px',
        border: 'none',
        color: APP_COLORS.BUTTON_BACKGROUND,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    }
}