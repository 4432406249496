import React , {useEffect} from 'react';
import '../../App.css';
import { APP_COLORS } from '../../styles';


export default function Dialogue({headerText, content, footerContent, show, onClose}){

  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  };
  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    document.body.style.overflowY = "hidden"
    
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
      document.body.style.overflowY = "scroll"
    };
  }, []);
  return(
    <div className = {`modal ${show ? 'show' : ''}`}  onClick={onClose}>
      
      <div className="modal-content" onClick={e => e.stopPropagation()}>
      <div className="modal-header">
      <h4 className="modal-title"> {headerText} </h4>
      <div style={{fontSize:'20px', position:'absolute',top:2, right:10, cursor:'pointer', padding:'10px', color:APP_COLORS.LINK_BLUE}} onClick={()=>onClose()}>x</div>
      </div>

      <div className="modal-body">
        {content}
      </div>

      {/* <div className="modal-footer">
        {footerContent}
      </div> */}

      </div>
    </div>
  )
}