import {FaRegHandshake , FaUserShield} from 'react-icons/fa';
import {RiShieldFlashFill} from 'react-icons/ri';
import Card from './ValueCard';
import { APP_COLORS } from '../styles';




export default function Values (){

    let isTablet = window.innerWidth < 800
    let isMobile = window.innerWidth < 500
    return (
        <div style={styles.valuesContainer(isTablet)}>
            
            <Card 
            icon={ <FaRegHandshake size={isMobile ? '32px' : '52px'} color= {APP_COLORS.BUTTON_BACKGROUND} /> } 
            title = 'Best Deals'
            text = 'Our deals are nearly 30 to 40 percent less than the market value.'
            style={{width: isMobile && '120px', height: isMobile && '160px'}}
            
            />
            <Card 
            icon={ <FaUserShield size={isMobile ? '32px' : '52px'} color= {APP_COLORS.BUTTON_BACKGROUND} /> } 
            title = 'Trustworthy Partners'
            text = 'We look for long term relations with our clients  and offer customised deals over the period of time.'
            style={{width: isMobile && '120px', height: isMobile && '160px'}}
            />
            <Card 
            icon={ <RiShieldFlashFill size={isMobile ? '32px' : '52px'} color= {APP_COLORS.BUTTON_BACKGROUND} /> } 
            title = 'Quick Closings'
            text = 'We try to close within seven to fourteen days after inquiry.'
            style={{width: isMobile && '120px', height: isMobile && '160px'}}
            />
            
        </div>
    )
}

const styles = {
    valuesContainer:(isTablet)=> ({
        width:'100%',
        position: !isTablet && 'absolute',
        boxSizing:'border-box',
        padding:'20px 20px',
        bottom:-245,
        zIndex:10,
        display:'flex',
        flexDirection: isTablet && 'column',
        alignItems: isTablet && 'center',
        justifyContent:'center',
        gap:20,
        })
}