import { useState } from 'react';
import { APP_COLORS } from "../styles";
import { BsInstagram, BsFacebook, BsLinkedin, BsFillArrowUpSquareFill } from 'react-icons/bs';

import Privacy from './Privacy';

export default function Footer() {
   
    let [showPrivacy, setShowPrivacy] = useState()
    let isMobile = window.innerWidth < 500;

    return (
        <div style={{
            position: 'relative',
            height: '70px',
            background: APP_COLORS.BUTTON_BACKGROUND,
            padding: isMobile ? '20px 20px' : '0px 10px',
            gap:10,
            display: 'flex',
            flexDirection: isMobile && 'column',
            justifyContent:  'space-between',
            alignItems: 'center',
            color: 'white'
        }}>
            <div style={{ cursor: 'pointer', position: 'absolute', right: 0 , top: -8 }}>
                <BsFillArrowUpSquareFill
                    style={{ padding: 10, fontSize: '22px', }}
                    onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }} />
            </div>

            <div style={{ cursor: 'pointer', }} onClick={() => { setShowPrivacy(true) }}>
                Terms of Use and Privacy Policy
            </div>

            <div>
            &copy; 2022 All Rights Reserved.
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                cursor: 'pointer'
            }}>
                Follow us on :
                <div className='social-icons'>
                    <BsInstagram color='white' size={'14'} />
                </div>
                <div className='social-icons'>
                    <BsFacebook color='white' size={'14'} />
                </div>

            </div>
            <Privacy show={showPrivacy} onClose={()=>setShowPrivacy(null)} />
            {/* {showPrivacy &&
                <Dialogue
                    content={
                        <div
                            style={{
                                height: "600px",
                                width: '100%',
                                background: APP_COLORS.META_BACKGROUND,
                                overflowY: 'scroll',
                                color: 'black',
                                padding: isMobile ? '10px 10px' : '30px 30px',
                            }}
                            >
                            <div style={{ padding: '10px 20px' }}>
                                <h1 style={styles.title}>Terms of Use</h1>

                                <h2 style={styles.title}>1. Acceptance of Terms</h2>
                                <p style={styles.content}>By accessing or using the services provided by Homedealers ("Company," "we," "us," or "our"), including
                                 but not limited to our website, you agree to comply with and be bound by these Terms of Use. If you do not agree to these terms, please refrain 
                                 from using our services.</p>

                                <h2 style={styles.title}>2. Description of Services</h2>
                                <p style={styles.content}> Homedealers offers real estate marketing and lead generation services to realtors, investors, brokers, and other individuals or entities involved in the real estate industry. Our services include but are not limited to marketing promotions, offers, and onboarding assistance.</p>


                                <h2 style={styles.title}>3. User Accounts</h2>
                                <p style={styles.content}> To access certain features of our services, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account information, and you agree to accept responsibility for all activities that occur under your account.</p>

                                <h2 style={styles.title}>4. Privacy Policy</h2>
                                <p style={styles.content}> Our Privacy Policy governs the collection, use, and disclosure of personal information provided by users. By using our services, you agree to the terms outlined in our Privacy Policy.</p>


                                <h2 style={styles.title}>5. Communication</h2>
                                <p style={styles.content}>By providing your contact information, you consent to receive emails, text messages, and calls from Homedealers for marketing promotions, offers, and onboarding purposes. You may opt-out of receiving such communications at any time by following the instructions provided in the communication.</p>

                                <h2 style={styles.title}>6. Intellectual Property</h2>
                                <p style={styles.content}>All content and materials available on our website, including but not limited to text, graphics, logos, button icons, images, and software, are the property of Homedealers and are protected by copyright, trademark, and other intellectual property laws.</p>

                                <h2 style={styles.title}>7. Limitation of Liability</h2>
                                <p style={styles.content}>To the extent permitted by law, Homedealers shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our services.</p>

                                <h2 style={styles.title}>8. Changes to Terms</h2>
                                <p style={styles.content}>We reserve the right to update or modify these Terms of Use at any time without prior notice. It is your responsibility to review these terms periodically for changes.</p>
                                
                                
                                <h1 style={styles.title}>Privacy Policy</h1>
                                <h2 style={styles.title}>1. Information We Collect</h2>
                                <p style={styles.content}>We collect personal information, including but not limited to names, contact details, and professional information, to provide our real estate marketing and lead generation services. We may also collect information about your interactions with our website.</p>
                                
                                <h2>2. How we collect information</h2>
                                <h3>How are you obtaining consent to send SMS</h3>
                                <p>We provide an online form for customers to enter information and check a box giving consent to receive SMS messages, The URL is the following: <a style={{color:'blue'}} href='https://home-dealers.com/' target='_blank' >https://home-dealers.com</a> ( By clicking on the option Contact us our customer will see the intake form and agreeing to receive SMS or Text messages from our company, furthermore below of this intake form we have the: Terms and conditions ( privacy policy) mentioned that we take care the information of our customer and we will not share that information with third party. </p>
                                
                                <h2 style={styles.title}>3. Use of Information</h2>
                                <p style={styles.content}>We use the collected information to deliver and improve our services, personalize user experience, communicate with users, and for marketing purposes. </p>
                            
                                <h2 style={styles.title}>4. Communication</h2>
                                <p style={styles.content}>By providing your contact information, you consent to receiving emails, text messages, and calls from Homedealers for marketing promotions, offers, and onboarding purposes. You can opt-out of such communications at any time.</p>

                                <h2 style={styles.title}>5. Security</h2>
                                <p style={styles.content}>We employ industry-standard security measures to protect the confidentiality and integrity of your personal information. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

                                <h2 style={styles.title}>6. Changes to Privacy Policy</h2>
                                <p style={styles.content}>We reserve the right to update or modify our Privacy Policy at any time. Changes will be posted on this page, and it is your responsibility to review the policy periodically.By using our services, you agree to the terms outlined in both the Terms of Use and Privacy Policy. If you have any 
                                   questions or concerns, please contact us at Homedealers.</p>
                            </div>
                        </div>
                    }
                    show={showPrivacy}
                    onClose={() => setShowPrivacy(null)}
                />
            } */}

        </div>

    )
}

const styles={
    title:{
        color:'black',
      },
      content:{
        color:'#444',
        lineHeight:'1.3',
        paddingTop:'10px'
      }
}