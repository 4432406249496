function Select ({options, name,  value, onChange, style}){
    return (
      <div style={{...styles.selectWrapper, ...style, }}>
      <select onChange={onChange} name={name}  value={value} style={{...styles.select, ...style}} >
      {options && options.map(op => (
        <option key={op.id} id={op.id} value={op.id}> {op.text} </option>
      ))}  
      </select>
      </div>
    )
  }
  export default Select;
  
  const styles={
    selectWrapper:{
      border: '1px solid #e1e1e1',
      padding:'5px 10px',
      borderRadius:'10px',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
    },
    select:{
      background:"inherit",
      width:'100%',
      height:'30px',
      border:'none',
      outline:'none',
    },
  }